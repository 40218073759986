import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';
import debounce from 'lodash.debounce';

import { users_password_complexity_path } from 'js/routes';

interface PasswordStrengthEvent extends Event {
  params: {
    identifier: {
      email: string | null;
      reset_password_token: string | null;
    };
  };
}

export default class extends Controller<HTMLElement> {
  static targets = ['input'];
  declare readonly inputTarget: HTMLInputElement;

  debouncedCheck?: ReturnType<typeof debounce>;
  abortController?: AbortController;

  connect() {
    this.debouncedCheck = debounce((event: PasswordStrengthEvent) => this.check(event), 350);
  }

  update(event: PasswordStrengthEvent) {
    this.debouncedCheck?.(event);
  }

  async check(event: PasswordStrengthEvent) {
    const password = this.inputTarget.value;
    const identifier = event.params.identifier;

    if (this.abortController) {
      this.abortController.abort('outdated');
    }

    this.abortController = new AbortController();

    try {
      const request = new FetchRequest('post', users_password_complexity_path(), {
        body: JSON.stringify({ user: { password, ...identifier } }),
        responseKind: 'turbo-stream',
        signal: this.abortController.signal,
      });
      await request.perform();
    } catch (e) {
      if (e !== 'outdated') {
        throw new Error(`Error when fetching password complexity: ${e as string}`);
      }
    } finally {
      delete this.abortController;
    }
  }
}
